import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

export function getMonthNames() {
  return dayjs.months();
}

export function getCurrent(unit) {
  return dayjs().get(unit);
}

export function formatDate(date, format) {
  if (!date) {
    return null;
  }
  return dayjs(date).format(format);
}

export function parseDate(date) {
  return dayjs(date)
}

export function generatePastYear(len = 5) {
  return Array.from({ length: len }, (_, index) => new Date().getFullYear() - index)
}

export function translateMonthNumber(i) {
  return {
    1: 'Januari',
    2: 'Februari',
    3: 'Maret',
    4: 'April',
    5: 'Mei',
    6: 'Juni',
    7: 'Juli',
    8: 'Agustus',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Desember'
  }[i]
}