<template>
  <base-modal :showing="visible" v-on:close="handleClose">
    <form class="space-y-6" v-on:submit.prevent="handleSubmit">
      <div class="flex items-center space-x-4 border-b pb-4">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Edit Periode Normal
        </h3>
      </div>
      <base-input
        inset
        label="Tanggal Akhir Periode Normal"
        with-label
        fullwidth
        type="date"
        v-model="form.end_date"
      />
      <div class="flex justify-end">
        <base-button :loading="loading">Simpan</base-button>
      </div>
    </form>
  </base-modal>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import dayjs from 'dayjs';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MitraPeriodEditDate',
  components: { BaseButton, BaseInput, BaseModal },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'updated'],
  data: function () {
    return {
      form: {
        end_date: null,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      mitraPeriod: 'mitra_periods/getMitraPeriod',
    }),
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.setup();
      }
    },
  },
  methods: {
    ...mapActions({
      updateMitraPeriod: 'mitra_periods/updateMitraPeriod',
    }),
    setup: function () {
      this.form.end_date = dayjs(
        this.mitraPeriod.data.attributes.end_date
      ).format('YYYY-MM-DD');
    },
    handleClose: function () {
      this.$emit('close');
    },
    handleSubmit: function () {
      this.loading = true;

      const payload = {
        data: {
          type: 'mitra-periods',
          id: this.mitraPeriod.data.id,
          attributes: {
            end_date: dayjs(this.form.end_date).utc(true),
          },
        },
      };

      this.updateMitraPeriod({ ...payload, setLoader: false })
        .then((res) => {
          if (res) {
            this.$emit('updated', res.data.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created: function () {
    if (this.mitraPeriod.data.attributes) {
      this.setup();
    }
  },
};
</script>
