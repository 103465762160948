<template>
  <ul role="list" class="divide-y rounded-md border">
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Normal</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :loading="loading.downloadNormal"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <div class="flex gap-x-2" v-else>
          <a
            :href="mitraPeriod.data.attributes.files.period.cashbill"
            target="_blank"
          >
            <base-button
              :loading="loading.downloadNormal"
              :disabled="
                disabled || !checkExported({ type: 'cashbill_period' })
              "
              size="sm"
              class="w-full"
            >
              {{
                checkExported({ type: 'cashbill_period' })
                  ? 'Download General'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
          <a
            :href="mitraPeriod.data.attributes.files.period['cashbill-detail']"
            target="_blank"
          >
            <base-button
              :loading="loading.downloadNormal"
              :disabled="
                disabled || !checkExported({ type: 'cashbill_period_detail' })
              "
              size="sm"
              class="w-full"
            >
              {{
                checkExported({ type: 'cashbill_period_detail' })
                  ? 'Download Detail'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
        </div>
      </div>
    </li>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Normal Nuera</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <div class="flex gap-x-2" v-else>
          <a
            :href="mitraPeriod.data.attributes.files.period['cashbill-nuera']"
            target="_blank"
          >
            <base-button
              :disabled="
                disabled || !checkExported({ type: 'cashbill_nuera' })
              "
              size="sm"
              class="w-full"
            >
              {{
                checkExported({ type: 'cashbill_nuera' })
                  ? 'Download General'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
          <a
            :href="mitraPeriod.data.attributes.files.period['cashbill-nuera-detail']"
            target="_blank"
          >
            <base-button
              :disabled="
                disabled || !checkExported({ type: 'cashbill_nuera_detail' })
              "
              size="sm"
              class="w-full"
            >
              {{
                checkExported({ type: 'cashbill_nuera_detail' })
                  ? 'Download Detail'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
        </div>
      </div>
    </li>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Tambah Point</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <div v-if="bonus_order_days.length" class="flex items-center">
          <base-chevron
            :collapse="collapsedBonus"
            v-on:click="collapsedBonus = !collapsedBonus"
          >
            <template #left>
              <span class="flex-shrink-0 text-gray-400"
                >{{ bonus_order_days.length }} File</span
              >
            </template>
          </base-chevron>
        </div>
        <base-button v-else disabled size="sm" class="w-full">
          Belum diproses
        </base-button>
      </div>
    </li>
    <template v-if="!collapsedBonus">
      <li
        v-for="bonus_day in bonus_order_days"
        :key="bonus_day.date"
        class="flex items-center justify-between bg-gray-100 p-4 text-sm leading-6"
      >
        <div class="flex w-0 flex-1 items-center">
          <div class="flex min-w-0 flex-1 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
            <span class="truncate">{{
              bonus_day.date | formatDate('LL')
            }}</span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <div class="flex gap-x-2">
            <a
              :href="mitraPeriod.data.attributes.files[bonus_day.date].cashbill"
              target="_blank"
            >
              <base-button
                :loading="bonus_day.loading"
                :disabled="
                  !checkExported({
                    type: 'cashbill_bonus_day',
                    date: bonus_day.date,
                  })
                "
                size="sm"
                class="w-full"
              >
                {{
                  checkExported({
                    type: 'cashbill_bonus_day',
                    date: bonus_day.date,
                  })
                    ? 'Download General'
                    : 'Tidak ada ekspor'
                }}
              </base-button>
            </a>
            <a
              :href="
                mitraPeriod.data.attributes.files[bonus_day.date][
                  'cashbill-detail'
                ]
              "
              target="_blank"
            >
              <base-button
                :loading="bonus_day.loading"
                :disabled="
                  !checkExported({
                    type: 'cashbill_bonus_day_detail',
                    date: bonus_day.date,
                  })
                "
                size="sm"
                class="w-full"
              >
                {{
                  checkExported({
                    type: 'cashbill_bonus_day_detail',
                    date: bonus_day.date,
                  })
                    ? 'Download General'
                    : 'Tidak ada ekspor'
                }}
              </base-button>
            </a>
          </div>
        </div>
      </li>
    </template>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Gabungan</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :loading="loading.downloadMix"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <div class="flex gap-x-2" v-else>
          <a
            :href="mitraPeriod.data.attributes.files.mixed.cashbill"
            target="_blank"
          >
            <base-button
              :loading="loading.downloadMix"
              :disabled="disabled || !checkExported({ type: 'cashbill_mixed' })"
              size="sm"
              class="w-full"
            >
              {{
                checkExported({ type: 'cashbill_mixed' })
                  ? 'Download General'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
          <a
            :href="mitraPeriod.data.attributes.files.mixed['cashbill-detail']"
            target="_blank"
          >
            <base-button
              :loading="loading.downloadMix"
              :disabled="
                disabled || !checkExported({ type: 'cashbill_mixed_detail' })
              "
              size="sm"
              class="w-full"
            >
              {{
                checkExported({ type: 'cashbill_mixed_detail' })
                  ? 'Download Detail'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import dayjs from 'dayjs';
import { downloadFile } from '@/services/utils.service.js';
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseChevron from '@/components/base/BaseChevron.vue';

export default {
  name: 'MitraPeriodOrderDownloadList',
  components: { BaseButton, BaseChevron },
  data: function () {
    return {
      collapsedBonus: true,
      bonus_order_days: [],
      loading: {
        downloadNormal: false,
        downloadMix: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      mitraPeriod: 'mitra_periods/getMitraPeriod',
      currentPeriod: 'mitra_periods/getCurrentPeriod',
    }),
    disabled: function () {
      return dayjs(this.currentPeriod.data.attributes.end_date).isBefore(
        dayjs(this.mitraPeriod.data.attributes.end_date).endOf('day')
      );
    },
  },
  methods: {
    ...mapActions({
      exportDbf: 'orders/exportDbf',
    }),
    setup: function () {
      this.bonus_order_days =
        this.mitraPeriod.data.attributes.bonus_order_days.map((day) => ({
          date: day,
          loading: false,
        }));
    },
    checkExported({ type, date }) {
      switch (type) {
        case 'cashbill_period':
          return (
            this.mitraPeriod.data.attributes.files.period.cashbill !== null
          );

        case 'cashbill_period_detail':
          return (
            this.mitraPeriod.data.attributes.files.period['cashbill-detail'] !==
            null
          );

        case 'cashbill_nuera':
          return (
            this.mitraPeriod.data.attributes.files.period['cashbill-nuera'] !== null
          );

        case 'cashbill_nuera_detail':
          return (
            this.mitraPeriod.data.attributes.files.period['cashbill-nuera-detail'] !==
            null
          );

        case 'cashbill_mixed':
          return this.mitraPeriod.data.attributes.files.mixed.cashbill !== null;

        case 'cashbill_mixed_detail':
          return (
            this.mitraPeriod.data.attributes.files.mixed['cashbill-detail'] !==
            null
          );

        case 'cashbill_bonus':
          return this.mitraPeriod.data.attributes.files.bonus.cashbill !== null;

        case 'cashbill_bonus_detail':
          return (
            this.mitraPeriod.data.attributes.files.bonus['cashbill-detail'] !==
            null
          );

        case 'cashbill_bonus_day':
          return this.mitraPeriod.data.attributes.files[date].cashbill !== null;

        case 'cashbill_bonus_day_detail':
          return (
            this.mitraPeriod.data.attributes.files[date]['cashbill-detail'] !==
            null
          );

        default:
          break;
      }
    },
    handleDownloadNormal: function () {
      this.loading.downloadNormal = true;

      const { month, year } = this.mitraPeriod.data.attributes;

      this.exportDbf({
        created_month: month,
        bonus_month: month,
        year,
      })
        .then((res) => {
          downloadFile(res.data, `cashbill-normal-bulan-${month}.dbf`);
        })
        .finally(() => {
          this.loading.downloadNormal = false;
        });
    },
    handleDownloadBonus: function ({ bonusOrderDay, bonusOrderDayIndex }) {
      this.bonus_order_days[bonusOrderDayIndex].loading = true;

      this.exportDbf({
        from_date: bonusOrderDay.date,
        to_date: bonusOrderDay.date,
      })
        .then((res) => {
          downloadFile(
            res.data,
            `cashbill-bonus-${dayjs(bonusOrderDay.date).format(
              'YYYY-MM-DD'
            )}.dbf`
          );
        })
        .finally(() => {
          this.bonus_order_days[bonusOrderDayIndex].loading = false;
        });
    },
    handleDownloadMix: function () {
      this.loading.downloadMix = true;

      const { month, year } = this.mitraPeriod.data.attributes;

      this.exportDbf({
        bonus_month: month,
        year,
      })
        .then((res) => {
          downloadFile(res.data, `cashbill-gabungan-bulan-${month}.dbf`);
        })
        .finally(() => {
          this.loading.downloadMix = false;
        });
    },
  },
  created: function () {
    this.setup();
  },
};
</script>
