<template>
  <div>
    <Datatable :paginated="false" :footer="false">
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Bulan
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Periode Normal
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Tanggal Tambah Point
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody v-if="mitraPeriods.data.length">
          <tr
            class="bg-white"
            v-for="(data, index) in mitraPeriods.data"
            :key="data.id"
            :class="[
              'cursor-pointer bg-white hover:bg-green-100',
              index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
              checkIsPeriodActive(data) ? 'bg-green-200 font-bold' : '',
            ]"
            v-on:click="handleClickRow(data)"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
              <div class="flex items-center gap-x-2">
                <span>{{
                  dayjs()
                    .set('M', data.attributes.month - 1)
                    .format('MMMM')
                }}</span>
                <base-badge
                  v-if="data.attributes.is_processing"
                  size="sm"
                  color="yellow"
                  >Sedang Diproses</base-badge
                >
              </div>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
              {{ formatStartDate(data.attributes.start_date) }} -
              {{ data.attributes.end_date | formatDate('LL LT') }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
              {{ data.attributes.bonus_start_date | formatDate('LL') }} -
              {{ data.attributes.bonus_end_date | formatDate('LL') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="bg-white">
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              colspan="2"
            >
              Data tidak ditemukan
            </td>
          </tr>
        </tbody>
      </template>
    </Datatable>

    <!-- Loader -->
    <loading
      v-if="mitraPeriodLoading || ordersLoading || mitraPeriodCurrentLoading"
    ></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import BaseBadge from '@/components/base/BaseBadge.vue';

dayjs.extend(isBetween);

export default {
  name: 'SetupPeriodeTambahPointBonus',
  components: {
    Datatable,
    BaseBadge,
  },
  emits: ['show'],
  computed: {
    ...mapGetters({
      mitraPeriods: 'mitra_periods/getMitraPeriods',
      mitraPeriodLoading: 'mitra_periods/getLoading',
      ordersLoading: 'orders/getLoading',
      mitraPeriodCurrentLoading: 'mitra_periods/getLoadingCurrentPeriod',
      currentPeriod: 'mitra_periods/getCurrentPeriod',
    }),
  },
  methods: {
    ...mapActions({
      fetchCurrentMitraPeriod: 'mitra_periods/fetchCurrentMitraPeriod',
    }),
    dayjs,
    handleClickRow: async function (mitraPeriod) {
      this.$emit('show', mitraPeriod);
    },
    checkIsPeriodActive: function (period) {
      return period.id === this.currentPeriod?.data.id;
    },
    async setCurrentPeriod() {
      await this.fetchCurrentMitraPeriod({
        'fields[mitra-periods]':
          'month,start_date,end_date,bonus_start_date,bonus_end_date',
      });
    },
    formatStartDate(startDate) {
      const date = dayjs(startDate)
      const endOfMonth = date.isSame(date.endOf('month'), 'hour')

      return endOfMonth ? date.add('1', 'day').startOf('day').format('LL LT') : date.format('LL LT')
    }
  },
  created() {
    this.setCurrentPeriod();
  },
};
</script>
