<template>
  <ul role="list" class="divide-y rounded-md border">
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Normal</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled || !checkExported({ type: 'office_period' })"
          :loading="loading.downloadNormal"
          :disabled="disabled || !checkExported({ type: 'office_period' })"
          size="sm"
          class="w-full"
        >
          <span>{{ disabled ? 'Belum diproses' : 'Tidak ada ekspor' }}</span>
        </base-button>
        <a
          v-else
          :href="mitraPeriod.data.attributes.files.period.office"
          target="_blank"
        >
          <base-button
            :loading="loading.downloadNormal"
            :disabled="disabled"
            size="sm"
            class="w-full"
          >
            Download
          </base-button>
        </a>
      </div>
    </li>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="min-w-0 flex-1 gap-2">
          <span class="block truncate font-medium">Periode Tambah Point</span>
          <span class="block text-xs text-gray-500">
            {{
              mitraPeriod.data.attributes.bonus_start_date | formatDate('LL LT')
            }}
            -
            {{
              mitraPeriod.data.attributes.bonus_end_date | formatDate('LL LT')
            }}
          </span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <div v-if="bonus_order_days.length" class="flex items-center">
          <base-chevron
            :collapse="collapsedBonus"
            v-on:click="collapsedBonus = !collapsedBonus"
          >
            <template #left>
              <span class="flex-shrink-0 text-gray-400"
                >{{
                  bonus_order_days.length + (disabledBonusStart ? 1 : 0)
                }}
                File</span
              >
            </template>
          </base-chevron>
        </div>
        <base-button v-else disabled size="sm" class="w-full">
          Belum diproses
        </base-button>
      </div>
    </li>
    <template v-if="!collapsedBonus">
      <!-- Bonus Awal Periode Normal - Awal Periode Bonus -->
      <li
        class="flex items-center justify-between bg-gray-100 p-4 text-sm leading-6"
        v-if="disabledBonusStart"
      >
        <div class="flex w-0 flex-1 items-center">
          <div class="flex min-w-0 flex-1 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
            <span class="truncate">
              {{ bonus_start.start_date | formatDate('LL') }} -
              {{ bonus_start.end_date | formatDate('LL') }}
            </span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <a
            :href="mitraPeriod.data.attributes.files.bonus_start.office"
            target="_blank"
          >
            <base-button
              :loading="loading.downloadBonusStart"
              :disabled="
                disabled || !checkExported({ type: 'office_bonus_start' })
              "
              size="sm"
              class="w-full"
            >
              {{
                disabled
                  ? 'Belum Dimulai '
                  : checkExported({ type: 'office_bonus_start' })
                  ? 'Download'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
        </div>
      </li>
      <li
        v-for="bonus_day in bonus_order_days"
        :key="bonus_day.date"
        class="flex items-center justify-between bg-gray-100 p-4 text-sm leading-6"
      >
        <div class="flex w-0 flex-1 items-center">
          <div class="flex min-w-0 flex-1 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
            <span class="truncate">{{
              bonus_day.date | formatDate('LL')
            }}</span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <a
            :href="mitraPeriod.data.attributes.files[bonus_day.date].office"
            target="_blank"
          >
            <base-button
              :disabled="
                !checkExported({
                  type: 'office_bonus_day',
                  date: bonus_day.date,
                })
              "
              :loading="bonus_day.loading"
              size="sm"
              class="w-full"
            >
              {{
                checkExported({
                  type: 'office_bonus_day',
                  date: bonus_day.date,
                })
                  ? 'Download'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </a>
        </div>
      </li>
    </template>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Gabungan</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :loading="loading.downloadMix"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <a
          v-else
          :href="mitraPeriod.data.attributes.files.mixed.office"
          target="_blank"
        >
          <base-button
            :loading="loading.downloadMix"
            :disabled="disabled || !checkExported({ type: 'office_mixed' })"
            size="sm"
            class="w-full"
          >
            {{
              checkExported({ type: 'office_mixed' })
                ? 'Download'
                : 'Tidak ada ekspor'
            }}
          </base-button>
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import dayjs from 'dayjs';
import { downloadFile } from '@/services/utils.service.js';
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseChevron from '@/components/base/BaseChevron.vue';

export default {
  name: 'MitraPeriodeOfficeDownloadList',
  components: { BaseButton, BaseChevron },
  data: function () {
    return {
      collapsedBonus: true,
      bonus_start: {
        start_date: null,
        end_date: null,
      },
      bonus_order_days: [],
      loading: {
        downloadNormal: false,
        downloadMix: false,
        downloadBonusStart: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      mitraPeriod: 'mitra_periods/getMitraPeriod',
      currentPeriod: 'mitra_periods/getCurrentPeriod',
    }),
    disabled: function () {
      return dayjs(this.currentPeriod.data.attributes.end_date).isBefore(
        dayjs(this.mitraPeriod.data.attributes.end_date).endOf('day')
      );
    },
    disabledBonusStart: function () {
      return dayjs().isAfter(dayjs(this.bonus_start.end_date).endOf('day'));
    },
  },
  methods: {
    ...mapActions({
      exportDbf: 'offices/exportDbf',
    }),
    setup: function () {
      this.bonus_start.start_date = dayjs(
        this.mitraPeriod.data.attributes.end_date
      ).format('YYYY-MM-DD');
      this.bonus_start.end_date =
        this.mitraPeriod.data.attributes.bonus_order_days[0];
      this.bonus_order_days =
        this.mitraPeriod.data.attributes.bonus_order_days.map((day) => ({
          date: day,
          loading: false,
        }));
    },
    checkExported({ type, date }) {
      switch (type) {
        case 'office_period':
          return this.mitraPeriod.data.attributes.files.period.office !== null;

        case 'office_mixed':
          return this.mitraPeriod.data.attributes.files.mixed.office !== null;

        case 'office_bonus_start':
          return (
            this.mitraPeriod.data.attributes.files.bonus_start.office !== null
          );

        case 'office_bonus_day':
          return this.mitraPeriod.data.attributes.files[date].office !== null;

        default:
          break;
      }
    },
    handleDownloadNormal: function () {
      this.loading.downloadNormal = true;

      const { month, year } = this.mitraPeriod.data.attributes;

      this.exportDbf({
        created_month: month,
        bonus_month: month,
        year,
      })
        .then((res) => {
          downloadFile(res.data, `mitra-normal-bulan-${month}.dbf`);
        })
        .finally(() => {
          this.loading.downloadNormal = false;
        });
    },
    handleDownloadBonus: function ({ bonusOrderDay, bonusOrderDayIndex }) {
      this.bonus_order_days[bonusOrderDayIndex].loading = true;

      this.exportDbf({
        from_date: bonusOrderDay.date,
        to_date: bonusOrderDay.date,
      })
        .then((res) => {
          downloadFile(
            res.data,
            `mitra-bonus-${dayjs(bonusOrderDay.date).format('YYYY-MM-DD')}.dbf`
          );
        })
        .finally(() => {
          this.bonus_order_days[bonusOrderDayIndex].loading = false;
        });
    },
    handleDownloadBonusStart: function () {
      this.loading.downloadBonusStart = true;

      this.exportDbf({
        from_date: this.bonus_start.start_date,
        to_date: this.bonus_start.end_date,
      })
        .then((res) => {
          downloadFile(
            res.data,
            `mitra-bonus-bulan-awal-${this.mitraPeriod.data.attributes.month}.dbf`
          );
        })
        .finally(() => {
          this.loading.downloadBonusStart = false;
        });
    },
    handleDownloadMix: function () {
      this.loading.downloadMix = true;

      const { month, year } = this.mitraPeriod.data.attributes;

      this.exportDbf({
        bonus_month: month,
        year,
      })
        .then((res) => {
          downloadFile(res.data, `mitra-gabungan-bulan-${month}.dbf`);
        })
        .finally(() => {
          this.loading.downloadMix = false;
        });
    },
  },
  mounted: function () {
    this.setup();
  },
};
</script>
