<template>
  <div>
    <base-modal size="max-w-6xl" :showing="visible" v-on:close="handleClose">
      <div class="space-y-4" v-if="mitraPeriod.data.attributes">
        <div class="flex items-center space-x-4">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            {{ mitraPeriod.data.attributes.name }}
          </h3>
          <base-badge
            v-if="mitraPeriod.data.attributes.is_processing"
            size="sm"
            color="yellow"
            >Sedang Diproses</base-badge
          >
        </div>
        <dl class="grid grid-cols-1 gap-x-6 sm:grid-cols-2">
          <!-- Separator -->
          <div class="col-span-2 border-t"></div>
          <div class="py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              Tanggal Tambah Point
            </dt>
            <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2">
              <span
                >{{
                  mitraPeriod.data.attributes.bonus_start_date
                    | formatDate('LL')
                }}
                -
                {{
                  mitraPeriod.data.attributes.bonus_end_date | formatDate('LL')
                }}</span
              >
              <span
                v-if="!disabledEditBonusDate && !readOnly"
                class="ml-4 flex-shrink-0"
              >
                <button
                  type="button"
                  class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                  v-on:click="handleEditPeriodBonusDate"
                >
                  Edit
                </button>
              </span>
            </dd>
          </div>
          <div class="py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              Periode Normal
            </dt>
            <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2">
              <span
                >{{
                  periodeNormalStartDate
                }}
                -
                {{
                  mitraPeriod.data.attributes.end_date | formatDate('LL LT')
                }}</span
              >
              <span
                v-if="!disabledEditDate && !readOnly"
                class="ml-4 flex-shrink-0"
              >
                <button
                  type="button"
                  class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                  v-on:click="handleEditPeriodDate"
                >
                  Edit
                </button>
              </span>
            </dd>
          </div>
          <template v-if="!loading">
            <!-- Separator -->
            <div class="col-span-2 border-t"></div>
            <!-- Download Ekspor Mitra -->
            <div class="py-6 sm:col-span-1 sm:px-0">
              <dt class="text-sm font-medium leading-6 text-gray-900">
                Download Ekspor Mitra
              </dt>
              <dd class="mt-2 text-sm text-gray-900 sm:col-span-2">
                <mitra-period-office-download-list />
              </dd>
            </div>
            <!-- Download Ekspor Cashbill -->
            <div class="py-6 sm:col-span-1 sm:px-0">
              <dt class="text-sm font-medium leading-6 text-gray-900">
                Download Ekspor Cashbill
              </dt>
              <dd class="mt-2 text-sm text-gray-900 sm:col-span-2">
                <mitra-period-order-download-list
                  :mitra-period="mitraPeriod.data"
                />
              </dd>
            </div>
          </template>
        </dl>
      </div>
    </base-modal>

    <mitra-period-edit-date
      :visible="periodEditDateModal.visible"
      v-on:close="periodEditDateModal.visible = false"
      v-on:updated="handleUpdatedDate"
    />
    <mitra-period-edit-bonus-date
      :visible="periodEditBonusDateModal.visible"
      v-on:close="periodEditBonusDateModal.visible = false"
      v-on:updated="handleUpdatedBonusDate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseBadge from '@/components/base/BaseBadge.vue';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import MitraPeriodOfficeDownloadList from '@/components/period/mitra-period/mitra-period-office-download-list.vue';
import MitraPeriodOrderDownloadList from '@/components/period/mitra-period/mitra-period-order-download-list.vue';
import MitraPeriodEditDate from './mitra-period-edit-date.vue';
import MitraPeriodEditBonusDate from './mitra-period-edit-bonus-date.vue';

dayjs.extend(utc);

export default {
  name: 'MitraPeriodOrderModal',
  components: {
    BaseModal,
    BaseBadge,
    MitraPeriodOfficeDownloadList,
    MitraPeriodOrderDownloadList,
    MitraPeriodEditDate,
    MitraPeriodEditBonusDate,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mitraPeriodId: null,
    year: null,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'updated'],
  data: function () {
    return {
      collapsedBonus: true,
      periodEditDateModal: {
        visible: false,
      },
      periodEditBonusDateModal: {
        visible: false,
      },
    };
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.setMitraPeriod();
      }
    },
  },
  computed: {
    ...mapGetters({
      mitraPeriod: 'mitra_periods/getMitraPeriod',
      currentPeriod: 'mitra_periods/getCurrentPeriod',
      loading: 'mitra_periods/getLoading',
    }),
    disabledEditDate: function () {
      return dayjs(dayjs(this.currentPeriod.data.attributes.end_date)).isAfter(
        dayjs(this.mitraPeriod.data.attributes.end_date).endOf('day')
      );
    },
    disabledEditBonusDate: function () {
      return dayjs(dayjs()).isAfter(
        dayjs(this.mitraPeriod.data.attributes.bonus_start_date).endOf('day')
      );
    },
    periodeNormalStartDate() {
      const date = dayjs(this.mitraPeriod.data.attributes.start_date)
      const endOfMonth = date.isSame(date.endOf('month'), 'hour')

      return endOfMonth ? date.add('1', 'day').startOf('day').format('LL LT') : date.format('LL LT')
    }
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchMitraPeriod: 'mitra_periods/fetchMitraPeriod',
      updateMitraPeriod: 'mitra_periods/updateMitraPeriod',
    }),
    handleClose: function () {
      this.$emit('close');
    },
    handleEditPeriodDate: function () {
      this.periodEditDateModal.visible = true;
    },
    handleEditPeriodBonusDate: function () {
      this.periodEditBonusDateModal.visible = true;
    },
    handleUpdatedDate: function () {
      this.periodEditDateModal.visible = false;

      this.$emit('updated');
    },
    handleUpdatedBonusDate: function () {
      this.periodEditBonusDateModal.visible = false;

      this.$emit('updated');
    },
    setMitraPeriod: async function () {
      this.fetchMitraPeriod({ id: this.mitraPeriodId });
    },
  },
};
</script>
